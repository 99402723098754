import Alpine from "alpinejs";
import anchor from "@alpinejs/anchor";
import focus from "@alpinejs/focus";
import intersect from "@alpinejs/intersect";
import mask from "@alpinejs/mask";
import persist from "@alpinejs/persist";
import sort from "@alpinejs/sort";
import ui from "@alpinejs/ui";

window.Alpine = Alpine;

Alpine.plugin(anchor);
Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(mask);
Alpine.plugin(persist);
Alpine.plugin(sort);
Alpine.plugin(ui);

Alpine.start();
